@import "../../../assets/sass/breakpoints.scss";
@import '../../../assets/sass/_variables.scss';

.discoContainer {
    padding-top: 0px;
    width: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
    .flex_container {
        max-width: 1000px;
        margin-bottom: 100px;

        .cardContainer {
            .imageContainer {
                .poster {
                    background-color: rgba(0, 0, 0, 0.664);
                    width: 100%;
                }
            }
        }

        .cardExpanded {
            // grid-column: span 6;
            // grid-row: span 2;
            background-color: rgba(0, 0, 0, 0.719);
            .cardContainer {
                width: 100%;
                display: flex;

                .imageContainer {
                    width: 100%;
                    overflow: hidden;
                    .poster {
                        background-color: rgba(0, 0, 0, 0.664);
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: .3s;

                        &:hover {
                            transition: .3s;
                            transform: scale(1.07);
                        }
                    }
                }

                .isExpanded {
                    width: 60%;
                    height: auto;
                }

                .playerContainer {
                    width: 100%;
                    display: flex;
                    flex-direction: column; 
                    justify-content: space-between;
                    padding: 7px 15px;

                    .infoText {
                        h3 {
                            font-size: 1rem;
                        }
                        p {
                            font-size: 0.75rem;
                        }
                    }

                    .buttonContainer {
                        margin: 10px 0;

                        button {
                            font-size: 0.6rem;
                            padding: 6px;
                            border: none;
                            border-radius: 0px;
                        }
                        a {
                            color: black;
                            text-decoration: none;
                            padding: 10px;
                            background-color: lightgrey;
                            font-size: 0.6rem;
                            padding: 6px;
                            border: none;
                            border-radius: 0px;
                        }
                        a:nth-child(2) {
                            margin-left: 15px;
                        }
                        button:nth-child(2) {
                            margin-left: 15px;
                        }
                    }

                    .react-player {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    .playerWrapper {
                        width: 100%;
                        flex: 1 1 0;
                    }
                }
                
                [data-role=false]{
                    display: none;
                }
                
            }
          }
    }
}

.containerMobile {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .player_container{
        width: 100%;
        flex: 1 1 auto;
        padding: 0 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        .player_wrapper {
            flex: 1 1 0;

            .noPlayerMessage {
                width: 100%;
                height: 60vh;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
        }
       
    }
    .slide_container {
        margin-top: 20px;
        width: 100%;
        flex: 2 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.g-background-default {
    border-radius: 0px;
    border: unset;
}

@media (min-width: $break-xl) {
    .container {
        padding-top: 200px;
    }
}

@media (max-width: $break-xl) {
    .container {
        .flex_container {
            // grid-template-columns: 1fr 1fr 1fr ;
            .poster {
                width: 100%;
            }
            
        }
    }
}

@media (max-width: $break-large) {
    .container {
        .flex_container {
            // grid-template-columns: 1fr 1fr ;
            .poster {

            }
        }
    }
}
@media (max-width: $break-medium) {
    .container {
        align-items: unset;
        //  height: calc(100vh - $padding-top-container);
        .flex_container {
            // grid-template-columns: 1fr 1fr ;
            .card--expanded {
                
            }
        }
        .containerMobile {
            
        }
    }
}

@media (max-width: $break-small) {
    .container {
        .flex_container {
            grid-template-columns: 100% ;
        }
    }
}