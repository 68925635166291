
@import "../../../assets/sass/breakpoints.scss";
@import "../../../assets/sass/_variables.scss";

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .flex_container {
        margin-bottom: 30px;
        max-width: 1000px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;

        .item_container {
            background-color: rgba(0, 0, 0, 0.664);
            padding: 5px;

            .img_container {
                overflow: hidden;
                position: relative;
                img {
                    width: 100%;
                    transition: .2s;
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.475);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    transition:  .2s;
                }
            }

            &:hover {
                cursor: pointer;
                img {
                    overflow: hidden;
                    transform: scale(1.07);
                    transition: .2s;
                }

                .overlay {
                    opacity: 1;
                    transition: .2s;
                }
            }

            .infos_pochette {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .infos {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-size: $font-size-shop;
                    font-weight: 500;
                    padding: 3px;
                }

                .link_buy {
                    text-decoration: none;
                    text-align: end;
                    line-height: auto;
                    color: $color-hover;
                    font-weight: 600;
                    font-size: $font-size-shop;
                    padding: 6px;
                    transition: .2s;

                    &:hover {
                        background-color: $color-hover;
                        color: rgba(0, 0, 0, 0.671);
                        transition: .2s;
                    }
                }
            }
        }
    }
}

@media (max-width: $break-xl) {
    .container {
        .flex_container {
            grid-template-columns: 1fr 1fr 1fr ;
        }
    }
}


@media (max-width: $break-large) {
    .container {
        .flex_container {
            padding-right: 15px;
        }
    }
}

@media (max-width: $break-medium) {
    .container {
        .flex_container {
            padding: 0 10px;
            grid-template-columns: 1fr 1fr ;
            gap: 15px;
            margin-bottom: 50px;
            .item_container {
                width: 100%;
            } 
        }
    }
}

