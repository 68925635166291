.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: dense;
  }
  
  .grid-template-columns {
    grid-template-rows:  span 1;
  }

  .card--expanded {
    grid-column: span 6;
    grid-row: span 2;
  }

  @media screen and (max-width: 1200px) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .card--expanded {
      grid-column: span 3;
      grid-row: span 1;
    }
  }

  @media screen and (max-width: 992px) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .card--expanded {
      grid-column: span 3;
      grid-row: span 2;
    }
  }

  .card {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;

  }
  
  .card__avatar {
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    background-color: hsla(0, 0%, 0%, 0.2);
    margin-bottom: 1rem;
    display: none;
  }
  
  .card__title {
    height: 1rem;
    width: 7rem;
    background-color: hsla(0, 0%, 0%, 0.6);
    margin-bottom: 1rem;
  }
  
  .card__description {
    height: 2rem;
    width: 8rem;
    background-color: hsla(0, 0%, 0%, 0.2);
  }
  
  