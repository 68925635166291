@import '../../../assets/sass/breakpoints.scss';
@import '../../../assets/sass/_variables.scss';

.container {
    width: 100%;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    .flex_container {
        max-width: 1000px;
        width: 100%;
        display: grid;
        grid-template-areas: "logo"
                             "social";
        grid-template-columns: 1fr;
        grid-template-rows:  1fr 100px;
       
        .logos_container {
            background-color: rgba(0, 0, 0, 0.726);
            grid-area: logo;
            display: flex;
            align-items: center;

            .aft_logo {
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    background-color: #ffffffca;
                    height: 60%;
                    width: 2px;
                    right: 0%;
                    top: 20%;
                } 
            }

            .logo_container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                flex: 1 1 0;
                height: 600px;

                .img_container {
                    width: 100%;
                    height: 50%;
                    display: flex;
                    justify-content: flex-end;

                    .logo1 {
                        width: 50%;
                        max-width: 280px; 
                    }

                    .logo2 {
                        width: 70%;
                        max-width: 450px;
                        transition: .3s;
                    }

                    .logo1, .logo2 {
                        &:hover {
                            filter: drop-shadow(2px 2px 2px $color-hover);
                            transition: .3s;
                        }
                    }
                   
                }
                
                
                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 20px ;
                    span { 
                        font-size: 12px;
                    }
                }
            }
        }
        .social_container {
            grid-area: social;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .link {
                flex: 1 1 0;
                transition: .3s;

                a {
                    display: flex;
                    align-items: center;
                    span {
                        display: unset;
                        margin-left: 10px;
                    }
                }
               

                &:hover {
                    color: $color-hover;
                    cursor: pointer;
                    transition: .3s;
                }
            }
        }
    }
}


@media (max-width: $break-medium) { 
    .container {
        .flex_container {
            height: 100%;
            padding: 0px 30px 30px 30px;
            grid-template-rows:  1fr 70px;
            .logos_container {
                flex-direction: column;

                .aft_logo {
                    &:after {
                        height: 2px;
                        width: 60%;
                        top: 100%;
                        right: 20%;
                    } 
                }

                .logo_container {
                    justify-content: center ;
                    width: 90%;

                    .img_container { 
                        height: auto;

                        .logo2 {
                            width: 50%;
    
                        }
                    }
                }
            }

            .social_container {
                grid-area: social;
                display: flex;
                justify-content: space-around;
                align-items: flex-end;
                .link {
                    flex: unset;
                    a {
                        span {
                            display: none;
                        }
                    }
                    
                }
            }
        }
    }  
}

