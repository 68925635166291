@import "../../../assets/sass/breakpoints.scss";
@import '../../../assets/sass/_variables.scss';


.container {
    width: 100%;
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: smoothDisplay 2s linear;
    

    a {
        &:hover {
            cursor: pointer;
            color: linear-gradient(90deg, rgba(64,56,50,1) 0%, rgba(210,105,30,1) 35%, rgba(87,71,51,1) 100%);;
            transition: .3s;
        }
        .titles {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1 {
                text-align: center;
                text-transform: uppercase;
                font-weight: 600;
            }
            h3 {
                font-weight: 300;
            }
        }
    }
   
}
  
@keyframes smoothDisplay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: $break-large) {
    .container {
        justify-content: flex-end;
    }
}

@media (max-width: $break-small) {
    .container {
        a {
            .titles {
                h1 {
                    font-size: 44px;
                }
                h3 {
                     font-size: 16px;
                }
             }
        }  
    }
}