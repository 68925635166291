

/* colors */

$color-text: rgb(241, 241, 241);
$color-text2: red; 
$color-hover: chocolate;
$color-background: black;

/* font-size */

$font-size-shop: 10px;
$font-size-menu: 12px;
$title: 4.5rem;
$subtitle: 2rem;

$padding-top-container: 70px;

$font-weight: 300;

$height-footer: 100px;
$padding-bottom-containers: 100px;

