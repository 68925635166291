

.image_container {
    width: 200px;
    height: 200px;
    position: relative;
    .image_preview {
        width: 200px;
        height: auto;
    }

    .onDragMessage {
        position: absolute;
        top: 0;
        color: rgb(98, 98, 98);
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.cropper {
    width: 600px;
    height: auto;
}

.alert {
    border: 1px solid red;
}

