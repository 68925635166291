.icon {
    &:hover {
        cursor: pointer;
    }
}

.AddIcon {
    color: #ff7675;

    &:hover {
        color: #a31717;
    }
}

