@import "variables";
@import "breakpoints";
@import "fonts";
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

* {
    box-sizing: border-box;
    font-family: 'MyWebFont', sans-serif;
    letter-spacing: 1.2px;
}

body {
    margin: 0;
    background-color: $color-background;
    color: $color-text;
    // font-family: 'MyWebFont';
    font-weight: $font-weight;
    overflow-x: hidden;
    
}

a {
    text-decoration: none;
    color: $color-text;
    transition: .3s;
    &:hover {
        color: $color-hover;
        transition: .3s;
    }
}


