@import "../../../assets/sass/breakpoints.scss";
@import '../../../assets/sass/_variables.scss';

.container_video {
    width: 100%;
    height: 100%;
    grid-area: menu;
    position: absolute;
}

.video_player, .image {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    transition: 1s;
    animation: smoothDisplay 1.3s linear;
}
.image {
    display: none;
}
.video {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    transition: 1s;
    filter: brightness(70%);
    animation: smoothDisplay 1.3s linear;
}

@keyframes smoothDisplay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: $break-medium) {
    .video_player {
        display: none;
    }
    .image {
        display: block;
        background: center / cover no-repeat url("../../../assets/design/images_back/fulldublive.jpeg");
    }
}