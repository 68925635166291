@import "../../../assets/sass/breakpoints.scss";
@import "../../../assets/sass/variables.scss";


.container {
    // padding-top: $padding-top-container;
    width: 100%;
    // min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .overlay {
        padding: 60px;
        min-height: 85vh;
        background-color: rgba(0, 0, 0, 0.733);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .dates_container {
            // margin-bottom: 30px;
            text-align: center;
            color: rgb(228, 228, 228);
            // margin-bottom: 90px;
            .title {
                h1 {
                    font-size: 2.5rem;
                    margin-bottom: 2rem;
                }
                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 1rem;
                }
            }

            .oldShows {
                margin-top: 70px;
            }

            .date_line {
                padding: 5px;
              
                div {
                    display: inline;
                }

                div {
                    .ticket_link {
                        .link {
                            color: $color-hover;
                            font-weight: 500;  
                            &:hover {
                                cursor: pointer;
                                color: rgb(241, 174, 73);
                                transition: .3s;
                            }
                        }
                    }
                }  
            }
        }  

        .load_more {
            text-align: center;
            color: $color-hover;
            font-weight: 500;
            transition: .3s;
            margin: 30px 0px;
            &:hover {
                cursor: pointer;
                color: rgb(241, 174, 73);
                transition: .3s;
            }
        }
    }
}

@media (min-width: $break-small) {
    .container {
        .overlay {
            .dates_container {
                .date_line {
                    span:not(:last-child) {
                        &:after {
                            content: " / ";
                        }
                    }  
                }
            }  
        }
    }
}


@media (max-width: $break-medium) {
    .container {
        margin-bottom: 50px;
        .overlay {
            width: 80%;
        }
    }
}

@media (max-width: $break-small) {
    .container {
        .overlay {
           width: 90%;
           padding: 15px;
        
            .dates_container {
                font-size: 12px;
                .date_line {
                   display: flex;
                   flex-direction: column;
                   align-items: center;
                   margin-bottom: 20px;

                   div {
                    display: block;

                        span:nth-child(1) {
                            &:after {
                                content: " - ";
                            }
                        }
                    }
                }
            }
        }
    }
}
