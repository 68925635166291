@import '../../../assets/sass/_variables.scss';

.footer {
    // background-color: rgba(0, 0, 0, 0.466);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 8px;
   

    a {
        padding: 5px 10px;
        color: rgba(189, 189, 189, 0.541);
    }
}