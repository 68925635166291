@import './assets/sass/variables';
* {
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}
h1 {
  font-size: $title;
}
h3 {
  font-size: $subtitle;
}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */ 
