@import "../../../assets/sass/breakpoints.scss";


.logo {
    position: fixed;
    width: 150px;
    margin: 20px;
    z-index: 500;
}

@media (max-width: $break-xl) {
    .logo {
        left: 0;
        width: 120px;
        margin: 20px;
    }
}

@media (max-width: $break-medium) {
    .logo {
        left: 0;
        width: 120px;
        margin: 20px;
    }
}