.container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
        font-size: 12px;
        margin-bottom: 15px;
    }
    .loading {
        width: 50vh;
        height: 3px;
        position: relative;
        overflow: hidden;

        .barloaderContainer {
            width: 100%;
            height: 3px;
            background-color: rgba(240, 255, 255, 0.733);
            animation: spin 2.5s cubic-bezier(.56,1.16,.52,.44);
            animation-iteration-count: infinite; 
        }
    }
}

@keyframes spin {
    0% {
        transform: translateX(-50vh);
    }
    50% {
        transform: translateX(50vh);
    }
    100% {
        transform: translateX(-50vh);
    }
}