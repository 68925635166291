@import "../../../assets/sass/breakpoints.scss";
@import '../../../assets/sass/variables';

.container {
    @extend .flexMenuDisplay;
    width: max-content;
    position: fixed;
    top: 200px;
    width: 160px;
    z-index: 500;
    font-weight: 400;
    .menuPublic, .menuAdmin {
        @extend .flexMenuDisplay;

        div {
            padding: 0px;
            margin-bottom: 7px;
            a {
                margin: 5px 0px 5px 0px;
                text-decoration: none;
                font-size: $font-size-shop;
                padding: 5px 5px 5px 5px;
                color: $color-text;
                text-transform: uppercase;
                &:hover {
                    filter: blur(0px);
                    background-color: rgba(255, 255, 255, 0.883);
                    color: black;
                }
            } 
        }

        .active {
            background-color: rgba(255, 255, 255, 0.883);
            color: black;
        }
    }
}


.flexMenuDisplay {
    display: flex;
    flex-direction: column;
}

@media (max-width: $break-medium) {
    .container {
        top: 0;
        width: 45%;
        justify-content: flex-start;
        align-items: flex-end;
        height: 100vh;
        transform: translateX(250%);
        background-color: rgba(0, 0, 0, 0.712);
        .menuPublic {
            margin-top: 10vh;

        }
        .menuPublic, .menuAdmin {
            width: 80%;
            display: flex;
            justify-content: center;

            > * {
                text-align: end;
                margin-right: unset;
                padding: 3px 7px 3px 15px;
            }
        }
    }
}
